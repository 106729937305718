@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.subscriptions{
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    margin-top: 40px;
    .subscriptions-header{
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 28px;
        margin-left: 40px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.02em;
    }
}
.slider-container{
    margin: 0 auto;
    width: 77vw;
}
.MuiSlider-root{
    color: #8588E3!important;
    margin-bottom: 30px!important;
}
.MuiSlider-rail{
    opacity: 1!important;
    color: #fff!important;
}
.MuiSlider-mark{
    color: #fff!important;
    width: 10px!important;
    height: 10px!important;
    border-radius: 50%!important;
    opacity: 1!important;
}
.MuiSlider-markActive{
    background-color: #8588E3!important;
}
.MuiSlider-markLabel {
    font-family: 'Montserrat', sans-serif!important;
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 0.75rem!important;
    line-height: 12px!important;
    text-align: center!important;
    letter-spacing: 0.02em!important;
}
.MuiSlider-markLabelActive{
    font-weight: 700!important;
}

//СТИЛИ КНОПОК
.toggle-button{
    margin: 10px!important;
    width: 30%;
    border: 1px solid #fff!important;
    border-radius: 15px!important;
    background-color: #fff!important;
    font-family: 'Montserrat', sans-serif!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 11px!important;
    line-height: 13px!important;
    text-align: center!important;
    letter-spacing: 0.02em!important;
    color: #000000!important;
    text-transform: none!important;
}
.toggle-button-group-container{
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}
.Mui-selected{
    background-color: #8588E3!important;
    color: #fff!important;
}

//СТИЛИ ОКНА ОТОБРАЖЕНИЯ ЦЕНЫ И СКИДКИ
.output-container{
    border: 3px solid #fff;
    border-radius: 10px;
    margin: 40px;
}
.output{
    display: flex;
    justify-content: space-between;
    margin: 44px 20px;
}
.output-text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
}
.output-button-container{
    display: flex;
    justify-content: center;
    margin-bottom: 36px;
}
.cost-output-value{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    margin-bottom: 12px;
}
.discount-output-value{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    margin-bottom: 12px;
}
.output-button{
    background-color: #743DE9;
    margin: 0 auto;
    border: none;
    border-radius: 16px;
    padding: 8px 24px;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
}




//стили модального окна
.modal{
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.modal-content{
    background: #fff;
    border-radius: 16px;
    height: 60%;
    width: 80%;
    padding: 20px;
    position: relative;
}
.close{
    position: absolute;
    top: 0;
    right: 0;
    margin: 24px;
    font-size: 32px;
}
.modal-header{
    margin-bottom: 36px;
    text-align: center;
    margin-top: 60px;
}
.user-input-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    max-width: 80%;
    margin: 0 auto;
}
.user-input-text{
    margin: 8px;
    margin-bottom: 4px;
    text-align: start;
}
.user-input{
    margin: 8px;
    border-radius: 4px;
    padding: 2px 4px;
    min-height: 24px;
}
.modal-button-container{
    display: flex;
    justify-content: center;
}
.modal-button{
    padding: 12px 24px;
    color: #fff;
    background-color: #743DE9;
    border: none;
    box-shadow: none;
    border-radius: 8px;
    margin-top: 24px;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    cursor: pointer;
}
.checkbox-container{
    display: flex;
    align-items: start;
    justify-content: center;
    font-size: 9px;
    font-family: 'Montserrat';
    margin-left: 12px;
    margin-top: 10px;
}
.checkbox-link{
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}
.checkbox{
    margin-bottom: 0;
    margin-right: 10px;
    margin-top: 8px;
}



@media (min-width:1200px) {
    .subscriptions{
        margin-top: 68px!important;
        max-width: 1440px!important;
        margin-left: auto;
        margin-right: auto;
    }
    .subscriptions-header{
        margin-left: unset!important;
    }
    .subscription-container{
        display: flex;
        justify-content: space-evenly;
    }
    .slider-buttons-container{
        width: 36%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .slider-container{
        width: 100%!important;
    }
    .MuiSlider-rail{
        height: 6px!important;
    }
    .MuiSlider-markLabel {
        font-size: 16px !important;
        line-height: 32px !important;
    }
    .MuiSlider-thumb{
        width: 32px!important;
        height: 32px!important;
    }
    .MuiSlider-mark {
        width: 16px !important;
        height: 16px !important;
    }
    .MuiToggleButtonGroup-root{
        width: 100%!important;
        margin-top: 60px!important;
    }
    .toggle-button{
        font-size: 20px!important;
        padding: 20px!important;
    
    }
    .output-container{
    width: 30%;
    min-width: 480px;
    max-width: 620px;
    }
    .subscriptions-header{
        font-size: 32px!important;
    }
    .output {
        margin: 58px!important;
    }
    .cost-output-value{
        font-size: 28px!important;
        margin-bottom: 22px!important;
    }
    .discount-output-value{
        font-size: 28px!important;
        margin-bottom: 22px!important;
    }
    .output-text{
        font-size: 24px!important;
    }
    .output-button-container{
        margin-bottom: 58px!important;
    }
    .output-button{
        padding: 24px 28px!important;
        font-size: 24px!important;
    }
    .modal-content{
        height: 500px!important;
        width: 60%!important;
        max-width: 1000px;
    }
    .modal-header{
        font-size: 28px;
    }
    .user-input-container{
        max-width: 60%!important;
    }
    .user-input {
        margin: 12px!important;
        border-radius: 8px!important;
        padding: 4px 8px!important;
        min-height: 36px!important;
    }
    .modal-button{
        margin-top: 36px!important;
        padding: 20px 36px!important;
        font-size: 20px!important;
        border-radius: 12px!important;
    }
    .checkbox-container{
        margin-left: unset!important;
        margin-top: unset!important;
    }
}
.contacts{
    background: rgba(217, 229, 221, 0.7);
    height: 100vh;
    width: 100vw;
}
.contacts-section{
    display: inline-block;
}
@media (min-width: 1200px) {
    .contacts{
        height: unset!important;
        min-height: 100vh;
        width: unset!important;
    }
    .contacts-section{
        display: flex;
        flex-direction: column;
        max-width: 70%;
        margin: 0 auto;
        padding-bottom: 60px;
    }
    
}
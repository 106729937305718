.home {
    text-align: center;
    background: url(./background.png);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  @media (min-width: 800px) {
    .home {
        background: url(./desktop_backgroung.png);
      }
      
  }
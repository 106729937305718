@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.map{
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    margin-top: 40px;
    .map-header{
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 28px;
        margin-left: 40px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.02em;
    }
}
.yandexmap-container{
    display: flex;
    justify-content: center;
    margin-left: 40px;
}


@media (min-width: 1200px) {
    .map{
        margin-top: 68px!important; 
    }
    .map-header{
        font-size: 32px!important;
        margin-bottom: 60px!important;  
        margin-left: 0px!important;  
    }
    .yandexmap-container{
        margin-left: unset!important;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.advantages{
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    margin-top: 40px;
    .advantages-header{
        margin-top: 0;
        margin-bottom: 28px;
        margin-left: 40px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.02em;
    }
    .advantages-item-text{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        margin-bottom: 0;
        margin-top: 0;
    }
    .advantages-item{
        margin-left: 44px;
        margin-top: 0px;
        margin-bottom: 20px;
        display: flex;
    }
    .advantages-item-img{
        margin-right: 20px;
        margin-top: 0;
        margin-left: 0;
    }

}
@media (min-width: 1200px) {
    .advantages{
        margin-top: 68px!important;
    }
    .advantages-header{
        font-size: 32px!important;
        margin-bottom: 60px!important;
    }
    .advantages-item{
        margin-bottom: 28px!important;
    }
    .advantages-item-text{
        font-size: 24px!important;
        line-height: unset!important;
    }
    .advantages-item-img{
        width: 30px;
        height: 30px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
.active-a{
    text-decoration: underline!important;
}
a{
    text-decoration: none;
    color: #fff;
    
}

.header {
    width: 100%;
    background: url(./background.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .box {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    margin-left: 124px;
    padding-bottom: 40px;
  }
  
  .menu {
    margin-top: 34px;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    font-size: 22px;
    justify-content: center;
    line-height: 28px;
    gap: 115px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    max-width: 740px;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #fff;
  }
  

  .mobile_btn {
    display: none;
  }
  
  @media (max-width: 1400px) {
    .box{
        padding-top: 20px;
        padding-bottom: 20px;
        margin-left: 34px;
    }
    .logo{
        width: 50px;
        height: 50px;
    }
    .mobile_btn {
      color: #fff;
      display: block;
      position: absolute;
      right: 28px;
      top: 28px;
      cursor: pointer;
      z-index: 10;
    }
  
    .menu {
      margin-top: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: fixed;
      left: -220%;
      right: 0;
      top: 0;
      bottom: 0;
      max-width: unset;
      width: 100%;
      height: 100vh;
      background-color: #8588E3;
      z-index: 10;
      transition: left 1s;
      font-size: 20px;
    }
  
    .active {
      left: 0;
    }
  
    .menu a {
      font-size: 28px;
    }
  }
.about{
    background: rgba(217, 229, 221, 0.7);
    min-height: 100vh;
    width: 100vw;
    max-width: 100vw;
}
@media (min-width: 1200px) {
    .about{
        width: unset!important;
    }
    .about-section{
        display: flex;
        justify-content: center;
        padding-bottom: 60px;
    }
    .benefits-advantages{
        width: 40%;
        margin-right: 60px;
    }
    
}
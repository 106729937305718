@font-face {
    font-family: "Buyan"; 
    src: url("./fonts/BuyanBold.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: bold; 
  }
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  .main-h1{
    color: #fff;
    font-size: 52px;
    margin-top: 200px;
    text-align: initial;
    font-family: 'Buyan' ;
    font-weight: bold;
    margin-left: 20px;
    max-width: 90%;
  }
  .toformbutton{
    position: absolute;
    left:50%;
    bottom: 0px;
   transform:translate(-50%, -50%);
   }
   .toformbutton:hover{
     cursor: pointer;
   }
   .toformbuuton-text{
     color: #fff;
     font-family: 'Montserrat', sans-serif;
     font-style: normal;
     font-weight: 500;
     font-size: 24px;
     line-height: 29px;
     letter-spacing: 0.02em;
     margin-bottom: 16px;
   }

   @media (min-width: 800px) {
      .main-h1{
        font-size: 128px;
        margin-left: 124px;
        margin-top: 256px;
      }
  }

  @media (min-width: 800px) and (min-height: 720px) and (max-height: 940px) {
    .main-h1{
      font-size: 80px;
      margin-left: 124px;
      max-width: 70%;
      margin-top: 128px;
    }
    .toformbutton{
      bottom: -20px;
    }
}

.modal-content{
  font-family: 'Montserrat', sans-serif;
}

@media (min-width: 1300px) {
  .toformbuuton-text{
    font-size: 40px;
    margin-bottom: 36px!important;
  }
  .toformbutton-img{
    width: 164px;
    height: 52px;
  }
}